import React from "react"
import { FaInfo, FaBlind, FaCloudSun } from "react-icons/fa"

export default [
  {
    icon: <FaInfo />,
    url: "https://tourisme-coeurdefrance.com/fr/",
  },
  {
    icon: <FaBlind />,
    url:
      "https://www.berryprovince.com/blog/22-randonnees-pour-parcourir-le-cher/",
  },
  {
    icon: <FaCloudSun />,
    url: "http://www.meteofrance.com/previsions-meteo-france/cher/18",
  },
]
