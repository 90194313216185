import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import StyledHero from "../components/StyledHero"
import styles from "../css/template.module.css"
import Img from "gatsby-image"
import { FaBed, FaWifi, FaCoffee } from "react-icons/fa"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import SEO from "../components/SEO"

const GiteTemplate = ({ data, pageContext: { next, previous } }) => {
  const {
    titre,
    prix,
    description: { description },
    topImage,
    images,
    wifi,
    petitDej,
    mention,
    mentionSuivante,
  } = data.gite

  return (
    <Layout>
      <SEO title={`Gîte ${titre} à Saint-Pierre-les-Étieux`} />
      <StyledHero img={topImage.fluid} />
      <section className={styles.template}>
        <div className={styles.center}>
          <h4>{titre}</h4>
          <p className={styles.desc}>{description}</p>
          <div className={styles.info}>
            <p>
              <FaWifi className={styles.icon} style={{ marginLeft: 0 }} />
              <FaBed className={styles.icon} />
              <span className={styles.price}>{prix}€</span>
              <FaCoffee className={styles.icon} />
              <span className={styles.price}>
                {petitDej <= 0 ? " inclus" : `${petitDej} €`}
              </span>
            </p>
          </div>
          <p className={styles.priceInfo}>
            {mention}
            <br />
            {mentionSuivante}
          </p>
          <p className={styles.priceInfo} />
          <div className={styles.images}>
            {images.map((item, index) => {
              return (
                <Img
                  key={`img-template-${index}`}
                  fluid={item.fluid}
                  alt={images.title}
                  className={styles.image}
                />
              )
            })}
          </div>
          <div className={styles.gitesNav}>
            <AniLink fade to={`/gites/${previous}`}>
              <FaChevronLeft />
            </AniLink>
            <AniLink fade to={`/gites/${next}`}>
              <FaChevronRight />
            </AniLink>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    gite: contentfulGite(slug: { eq: $slug }) {
      titre
      id: contentful_id
      prix
      description {
        description
      }
      topImage {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      images {
        fluid {
          ...GatsbyContentfulFluid
        }
        title
      }
      wifi
      petitDej
      mention
      mentionSuivante
    }
  }
`

export default GiteTemplate
