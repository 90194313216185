export default [
  {
    path: "/",
    text: "Accueil",
  },
  {
    path: "/lieu",
    text: "Lieu",
  },
  {
    path: "/gites",
    text: "Gîtes",
  },
  {
    path: "/region",
    text: "Région",
  },
  {
    path: "/contact",
    text: "contact",
  },
]
