import React from "react"
import styles from "../css/footer.module.css"
import links from "../constants/links"
import socialIcons from "../constants/social-icons"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.links}>
        {links.map((item, index) => {
          return (
            <AniLink fade key={`link-footer-${index}`} to={item.path}>
              {item.text}
            </AniLink>
          )
        })}
      </div>
      <div className={styles.icons}>
        {socialIcons.map((item, index) => {
          return (
            <a
              key={`social-footer-${index}`}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.icon}
            </a>
          )
        })}
      </div>
      <div className={styles.copyright}>
        copyright &copy; le moulin de gâteau {new Date().getFullYear()} - tous
        droits reservés -{" "}
        <AniLink fade to="/legal">
          mentions légales
        </AniLink>
      </div>
      <div className={styles.mentions}>
        <p>
          {" "}
          Site web développé par{" "}
          <a
            href="https://laurent-pollez.fr"
            rel="noopener noreferrer"
            target="_blank"
          >
            id Soluce
          </a>
          <br />
          Photos &amp; vidéo du lieu réalisées par William Abenhaim
          (william.abenhaim@gmail.com)
        </p>
      </div>
    </footer>
  )
}

export default Footer
